<template>
    <Splash v-if="isProfileLoading() || $apollo.queries.profile.loading" />
    <WithSidebar v-else>
        <template #main="{ toggleSidebarLeft }">
            <div class="profile bg-light text-primary">
                <div class="profile__scrollable-content">
                    <ProfileHeader :profile="profile" />
                    <header v-if="$mq === 'sm'" class="profile__top-nav header--absolute">
                        <div class="left">
                            <CurrentProfileBurger @click="toggleSidebarLeft" />
                        </div>
                        <img class="logo" src="@/assets/logo_white.svg" alt="logo" />
                        <img src="@/assets/ellipsis_white.svg" alt="ellipsis" />
                    </header>
                    <div class="profile__feed">
                        <h3>Status updates</h3>
                        <Post :data="{
                            avatar: require(`@/assets/Goose_2.png`),
                            name: 'Gus the Goose',
                            date: new Date(),
                            text: 'Når alt er på plass, vil denne feeden inneholde relevant informasjon for deg basert på dine interesser og hvor du bor. Enn så lenge vil jeg; Gus the Goose komme med noen små oppdateringer fremover, Rock on!',
                            image: require(`@/assets/Goose_2.png`)
                        }" />
                        <!-- <Post
                            v-for="i in 3"
                            :key="i"
                            :data="{
                                avatar: require('@/assets/austin.png'),
                                name: 'Austin The Singer',
                                date: new Date(),
                                text: 'Had a great time yesterday performing at @GooseRockFestival',
                                image: require('@/assets/austin.png')
                            }"
                        /> -->
                    </div>
                </div>
            </div>
        </template>
    </WithSidebar>
</template>

<script>
import Post from '@/components/Post.vue'
import WithSidebar from '@/components/layout/WithSidebar'
import CurrentProfileBurger from '@/components/CurrentProfileBurger'
import ProfileHeader from './ProfileHeader'

import { GET_FAN, GET_ARTIST, GET_VENUE } from '@/graphql/queries/user.js'

export default {
    components: {
        Post,
        WithSidebar,
        CurrentProfileBurger,
        ProfileHeader,
    },
    inject: ['getMyCurrentProfile', 'isProfileLoading', 'getCurrentProfileType'],
    apollo: {
        profile: {
            query() {
                return this.query
            },
            variables() {
                return {
                    id: this.$route.params.id
                }
            },
            update(res) {
                return res[this.$route.params.type]
            }
        }
    },
    data() {
        return {
            posts: [
                {
                    name: 'Austin The Singer',
                    avatar: 'concert.png',
                    date: new Date(),
                    content: 'Had a great time yesterday performing at @GooseRockFestival',
                    contentImage: 'concert.png'
                },
                {
                    name: 'Kanye East',
                    avatar: 'bird_splash.png',
                    date: new Date(),
                    content: 'I am Kanye East',
                    contentImage: 'bird_splash.png'
                },
                {
                    name: 'Michael',
                    avatar: 'bird_splash_shadow.png',
                    date: new Date(),
                    content: 'Have a nice day everyone',
                    contentImage: 'concert.png'
                }
            ]
        }
    },

    computed: {
        query() {
            switch (this.$route.params.type) {
                case 'fan':
                    return GET_FAN
                case 'artist':
                    return GET_ARTIST
                case 'venue':
                    return GET_VENUE
                default:
                    throw new Error('Unknown profile type name')
            }
        },
        currentProfileType() {
            return this.getCurrentProfileType()
        }
    },
    watch: {
        currentProfileType() {
            this.$apollo.queries.profile.refetch()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.profile {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    &__scrollable-content {
        overflow: auto;
        height: 100%;
        position: relative;
    }

    &__feed {
        padding: 2rem 1rem 110px;
        h3 {
            padding-left: 1rem;
            padding-bottom: 1rem;
        }
    }
    &__top-nav {
        align-content: end;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        height: 90px;
        padding: 1rem 1rem 0.5rem;
        position: absolute;
        top: 0;
        width: 100%;
        .image-burger-container {
            background: radial-gradient(rgba(0, 0, 0, 1) -200%, transparent);
            box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
        }
        .logo {
            justify-self: center;
            width: 50px;
            background: radial-gradient(rgba(0, 0, 0, 1) -200%, transparent);
            box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
        }
        > :last-child {
            justify-self: end;
            width: 20px;
        }
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .profile {
        // min-height: 100vh;
        height: auto;
        overflow: visible;
        position: static;

        &__scrollable-content {
            overflow: inherit;
            height: 100%;
            position: static;
        }

        &__feed {
            max-width: 700px;
            margin: auto;
            padding: 1rem;
        }
    }
}
</style>
