var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isFollowing)?_c('ApolloMutation',{attrs:{"mutation":_vm.followMutation,"variables":{
        input: {
            id: _vm.id,
            profileType: _vm.profileType
        }
    },"update":_vm.updateFollow,"optimistic-response":{
        __typename: 'Mutation',
        followProfile: true
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
return [_c('Button',{attrs:{"label":"Follow","square":"","size":"small"},on:{"click":mutate}})]}}],null,false,4021637242)}):_c('ApolloMutation',{attrs:{"mutation":_vm.unfollowMutation,"variables":{
        input: {
            id: _vm.id,
            profileType: _vm.profileType
        }
    },"update":_vm.updateUnfollow,"optimistic-response":{
        __typename: 'Mutation',
        unFollowProfile: true
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
return [_c('Button',{attrs:{"label":"Unfollow","square":"","size":"small","primary":""},on:{"click":mutate}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }