<template>
    <ApolloMutation
        v-if="!isFollowing"
        :mutation="followMutation"
        :variables="{
            input: {
                id: id,
                profileType
            }
        }"
        :update="updateFollow"
        :optimistic-response="{
            __typename: 'Mutation',
            followProfile: true
        }"
    >
        <template #default="{ mutate }">
            <Button label="Follow" square size="small" @click="mutate" />
        </template>
    </ApolloMutation>
    <ApolloMutation
        v-else
        :mutation="unfollowMutation"
        :variables="{
            input: {
                id: id,
                profileType
            }
        }"
        :update="updateUnfollow"
        :optimistic-response="{
            __typename: 'Mutation',
            unFollowProfile: true
        }"
    >
        <template #default="{ mutate }">
            <Button label="Unfollow" square size="small" primary @click="mutate" />
        </template>
    </ApolloMutation>
</template>

<script>
import {
    GET_FAN,
    GET_ARTIST,
    GET_VENUE,
    FOLLOW_PROFILE,
    UNFOLLOW_PROFILE
} from '@/graphql/queries/user.js'

const profileQueries = new Map([
    ['fan', GET_FAN],
    ['artist', GET_ARTIST],
    ['venue', GET_VENUE]
])

export default {
    name: 'FollowButton',
    props: {
        id: {
            type: String,
            required: true
        },
        isFollowing: {
            type: Boolean,
            required: true
        },
        profileType: {
            type: String,
            required: true
        }
    },

    computed: {
        query() {
            return profileQueries.get(this.profileType.toLowerCase())
        },
        followMutation() {
            return FOLLOW_PROFILE
        },
        unfollowMutation() {
            return UNFOLLOW_PROFILE
        }
    },
    methods: {
        updateFollow(cache) {
            const query = {
                query: this.query,
                variables: { id: this.$route.params.id }
            }
            const { fan, artist, venue } = cache.readQuery(query)
            const profile = fan || artist || venue
            const { meta } = profile
            const newMetaRef = {
                ...meta,
                followers: meta.followers + 1,
                isFollowing: true
            }
            cache.writeQuery({
                query: this.query,
                variables: { id: this.$route.params.id },
                data: {
                    [`${profile.__typename.toLowerCase()}`]: {
                        ...profile,
                        meta: newMetaRef
                    }
                }
            })
        },
        updateUnfollow(cache) {
            const query = {
                query: this.query,
                variables: { id: this.$route.params.id }
            }
            const { fan, artist, venue } = cache.readQuery(query)
            const profile = fan || artist || venue
            const { meta } = profile
            const newMetaRef = {
                ...meta,
                followers: meta.followers - 1,
                isFollowing: false
            }
            cache.writeQuery({
                query: this.query,
                variables: { id: this.$route.params.id },
                data: {
                    [`${profile.__typename.toLowerCase()}`]: {
                        ...profile,
                        meta: newMetaRef
                    }
                }
            })
        }
    }
}
</script>

<style></style>
