<template>
    <div class="post-card bg-white text-primary">
        <div class="post-card__header">
            <img class="post-card__avatar avatar-sm" :src="data.avatar" />
            <div class="post-card__name">{{ data.name }}</div>
            <img class="post-card__ellipsis" src="@/assets/ellipsis.svg" />
            <small class="post-card__date">
                {{ formattedDateString }}
            </small>
        </div>
        <div class="post-card__body">
            <div class="post-card__text">
                <p>{{ data.text }}</p>
            </div>
        </div>
        <div class="post-card__aside">
            <img :src="data.image" class="post-card__image" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => ({
                avatar: '',
                name: '',
                date: '',
                image: '',
                text: '',
            }),
        },
    },
    computed: {
        formattedDateString: function () {
            return this.data.date.toLocaleDateString('nor', {
                hour: 'numeric',
                minute: 'numeric',
                weekday: 'long',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.post-card {
    border-radius: 5px;
    &__header {
        display: grid;
        grid-template-columns: auto 1fr auto;
        place-items: center;
        column-gap: 0.75rem;
        padding: 0.75rem;
    }

    &__avatar {
        grid-row: 1 / 3;
    }

    &__name {
        justify-self: start;
    }

    &__ellipsis {
        font-size: 1.5rem;
        grid-row: 1 / 3;
        grid-column: 3 / 4;
    }

    &__date {
        grid-column: 2 / 3;
        justify-self: start;
    }

    &__body {
        padding: 0 0.75rem 0.5rem;
    }

    &__aside {
        padding: 0;
        margin: 0;
        // width: 100%;
        // height: auto;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}
</style>
