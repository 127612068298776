var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isProfileLoading() || _vm.$apollo.queries.profile.loading)?_c('Splash'):_c('WithSidebar',{scopedSlots:_vm._u([{key:"main",fn:function(ref){
var toggleSidebarLeft = ref.toggleSidebarLeft;
return [_c('div',{staticClass:"profile bg-light text-primary"},[_c('div',{staticClass:"profile__scrollable-content"},[_c('ProfileHeader',{attrs:{"profile":_vm.profile}}),(_vm.$mq === 'sm')?_c('header',{staticClass:"profile__top-nav header--absolute"},[_c('div',{staticClass:"left"},[_c('CurrentProfileBurger',{on:{"click":toggleSidebarLeft}})],1),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo_white.svg"),"alt":"logo"}}),_c('img',{attrs:{"src":require("@/assets/ellipsis_white.svg"),"alt":"ellipsis"}})]):_vm._e(),_c('div',{staticClass:"profile__feed"},[_c('h3',[_vm._v("Status updates")]),_c('Post',{attrs:{"data":{
                        avatar: require("@/assets/Goose_2.png"),
                        name: 'Gus the Goose',
                        date: new Date(),
                        text: 'Når alt er på plass, vil denne feeden inneholde relevant informasjon for deg basert på dine interesser og hvor du bor. Enn så lenge vil jeg; Gus the Goose komme med noen små oppdateringer fremover, Rock on!',
                        image: require("@/assets/Goose_2.png")
                    }}})],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }