<template>
    <div class="profile-details bg-white">
        <aside class="profile-details__header">
            <div class="profile-details__figure-cover">
                <img
                    :src="profile.coverUrl"
                    class="profile-details__cover-image image--fit-cover"
                    alt="cover"
                />
            </div>
            <div class="profile-details__figure-profile">
                <img
                    :src="profile.avatar"
                    class="profile-details__profile-image image--fit-cover"
                    alt="profile"
                />
            </div>
        </aside>
        <main class="profile-details__body grid">
            <div class="profile-details__name">
                <h2>{{ profile.name }}</h2>
                <p class="capitalize">
                    {{ profile.__typename }}
                </p>
            </div>

            <div class="separator"></div>

            <div v-if="profile.__typename !== 'Fan'" class="profile-details__meta">
                <div class="grid-meta">
                    <div class="grid-meta__item">
                        <img
                            class="grid-meta__left"
                            :style="{ width: '16px' }"
                            src="@/assets/icons/trophy_icon.svg"
                            alt="trophy"
                        />
                        <h4 class="grid-meta__top font-weight-medium">Gold</h4>
                        <small class="grid-meta__bottom capitalize"> Member </small>
                    </div>
                    <div class="grid-meta__item">
                        <img
                            class="grid-meta__left"
                            :style="{ width: '16px' }"
                            src="@/assets/icons/follower_icon.svg"
                            alt="trophy"
                        />
                        <h4 class="grid-meta__top font-weight-medium">
                            {{ profile.meta.followers }}
                        </h4>
                        <small class="grid-meta__bottom"> Fans </small>
                    </div>
                    <div class="grid-meta__item">
                        <img
                            class="grid-meta__left"
                            :style="{ width: '16px' }"
                            src="@/assets/icons/microphone_icon.svg"
                            alt="trophy"
                        />
                        <h4 class="grid-meta__top font-weight-medium">75</h4>
                        <small class="grid-meta__bottom"> Events </small>
                    </div>
                </div>
            </div>

            <div v-else class="profile-details__meta-fan">
                <div class="grid-meta">
                    <div class="grid-meta__item">
                        <img
                            class="grid-meta__left"
                            :style="{ width: '16px' }"
                            src="@/assets/icons/trophy_icon.svg"
                            alt="trophy"
                        />
                        <h4 class="grid-meta__top font-weight-medium">Silver</h4>
                        <small class="grid-meta__bottom capitalize">
                            {{ profile.__typename }}
                        </small>
                    </div>

                    <div class="grid-meta__item">
                        <img
                            class="grid-meta__left"
                            :style="{ width: '16px' }"
                            src="@/assets/icons/follower_icon.svg"
                            alt="trophy"
                        />
                        <h4 class="grid-meta__top font-weight-medium">
                            {{ profile.meta.following }}
                        </h4>
                        <small class="grid-meta__bottom"> Following </small>
                    </div>
                    <div class="grid-meta__item">
                        <img
                            class="grid-meta__left"
                            :style="{ width: '16px' }"
                            src="@/assets/icons/follower_icon.svg"
                            alt="trophy"
                        />
                        <h4 class="grid-meta__top font-weight-medium">
                            {{ profile.meta.followers }}
                        </h4>
                        <small class="grid-meta__bottom"> Fans </small>
                    </div>
                </div>
            </div>
            <div class="separator"></div>
            <div class="profile-details__description">
                {{ profile.description || 'Empty description' }}
            </div>
            <div class="separator"></div>
            <div v-if="shouldShowFollowButton" class="profile-details__footer">
                <FollowButton
                    :id="profile.id"
                    :profile-type="profile.__typename"
                    :is-following="profile.meta.isFollowing"
                />
                <router-link
                    v-slot="{ navigate }"
                    :to="{
                        name: 'conversation',
                        params: { profile }
                    }"
                >
                    <Button label="Message" size="small" square @click="navigate" />
                </router-link>
                <!-- <Button label="Booking" size="small" square /> -->
            </div>
            <div v-else>
                <Button label="Edit profile" size="small" square @click="$router.push('me/edit')" />
            </div>
        </main>
    </div>
</template>

<script>
import FollowButton from './FollowButton'
import { ME } from '@/graphql/queries/user'
import { isMyProfile } from '@/utils/user'
// import Media from '@/components/Media'
export default {
    name: 'ProfileHeader',
    components: {
        FollowButton
        // Media
    },
    inject: [],
    apollo: {
        me: {
            query: ME
        }
    },
    props: {
        profile: {
            type: Object,
            required: true
        }
    },

    computed: {
        shouldShowFollowButton() {
            return !isMyProfile(this.me, this.profile.id)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/sass/variables.scss';

.image--fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.separator {
    height: 1px;
    background: $light;
    width: 100%;
}
.profile-details {
    text-align: center;

    &__header {
        position: relative;
    }

    &__figure-cover {
        height: 210px;
    }

    &__figure-profile {
        border-radius: 50%;
        box-shadow: 0 0 10px 2px #fff;
        overflow: hidden;
        position: absolute;
        top: 90%;
        left: 50%;
        margin-left: -60px;
        margin-top: -60px;
        height: 120px;
        width: 120px;
    }

    &__body {
        padding: 4rem 2rem 1rem;
        display: grid;
        row-gap: 1rem;
        place-items: center;
    }

    &__meta {
        width: 100%;
    }
    &__description {
        word-break: break-word;
    }
    &__meta-fan {
        width: 100%;
    }
    &__footer {
        display: grid;
        grid-template-columns: repeat(3, auto);
        justify-content: center;
        column-gap: 0.5rem;
        button {
            padding-left: 0.3rem;
            padding-right: 0.3rem;
        }
    }
}

.grid-meta {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: stretch;

    &__top,
    &__bottom {
        text-align: left;
    }

    &__left {
        grid-row-end: span 2;
    }
    &__item {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .separator {
        max-width: 800px;
    }
    .profile-details {
        margin-left: auto;
        margin-right: auto;
        min-width: 400px;
        max-width: 1060px;
        &__figure-cover {
            height: 340px;
        }
        &__meta {
            max-width: 340px;
        }

        &__meta-fan {
            max-width: 340px;
        }
    }
}
</style>
